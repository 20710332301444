<template>
  <v-card class="pa-3">
    <h1>{{ $t("systemMenu.manage_residents.title") }}</h1>
    <v-layout justify-end>
      <v-btn
        color="primary"
        @click="showAddResidentDialogAndResetResidentName()"
        class="mr-2"
        rounded
        >{{ $t("systemMenu.manage_residents.add_resident") }}
      </v-btn>
    </v-layout>

    <v-text-field
      v-model="search"
      append-icon="mdi-magnify"
      :label="$t('systemMenu.manage_residents.search_resident')"
      single-line
      hide-details
      class="col-12"
    ></v-text-field>
    <v-data-table
      :headers="headers"
      :items="residents"
      class="elevation-1"
      :search="search"
      :loading="loading"
      :loading-text="$t('systemMenu.manage_residents.loading_text')"
    >
      <template v-slot:item="row">
        <tr>
          <td>
            {{ row.item.name }}
          </td>
          <td>
            <v-layout justify-end>
              <v-btn
                class="primary ml-2"
                :to="{
                  name: 'manageResidents',
                  params: { resident: { id: row.item.resident, name: row.item.name } },
                }"
                rounded
              >
                {{ $t("systemMenu.manage_residents.manage_resident") }}
              </v-btn>
            </v-layout>
          </td>
        </tr>
      </template>
    </v-data-table>

    <v-dialog v-model="showResidentAdded" persistent max-width="550">
      <v-card>
        <v-card-title class="text-h5">
          {{
            $t("systemMenu.manage_residents.resident_succesfully_added_title")
          }}
        </v-card-title>
        <v-card-text
          >{{
            $t(
              "systemMenu.manage_residents.resident_succesfully_added_description"
            )
          }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="refreshTable(); showResidentAdded = false;"> Ok </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showAddResidentDialog" persistent max-width="500">
      <v-card>
        <v-card-title class="text-h5">
          {{ $t("systemMenu.manage_residents.new_resident_name") }}:
        </v-card-title>
        <v-col md="9">
          <v-text-field
            v-model="newResidentName"
            name="name"
            type="text"
            prepend-icon="mdi-account-plus"
            class="ml-3"
            :label="$t('systemMenu.manage_residents.resident_name')"
          />
        </v-col>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="showAddResidentDialog = false">
            {{ $t("systemMenu.manage_residents.cancel") }}
          </v-btn>
          <v-btn color="blue darken-1" text @click="addResidentFunction">
            {{ $t("systemMenu.manage_residents.add") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showServerErrorDialog" persistent max-width="560px">
      <v-card>
        <v-card-title class="text-h5">{{
          $t("systemMenu.manage_residents.server_error")
        }}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="showServerErrorDialog = false"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showInsertNameError" persistent max-width="550">
      <v-card>
        <v-card-title class="text-h5">
          {{ $t("systemMenu.manage_residents.insert_name") }}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="showInsertNameError = false">
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import { ILLI_API_SERVER_URL } from "../config";

export default {
  data() {
    return {
      newResidentName: "",
      search: "",
      showResidentAdded: false,
      showAddResidentDialog: false,
      showInsertNameError: false,
      showServerErrorDialog: false,
      loading: true,
      residents: [],
      headers: [
        { text: "Naam", value: "name" },
        { text: "", value: "actions", sortable: false },
      ],
    };
  },
  mounted() {
    this.refreshTable()
  },
  computed: {
    ...mapGetters({
      system: "system/system",
      user: "auth/user",
    }),
  },
  methods: {
    refreshTable() {
      this.residents = [];
      this.loading = true;
      axios
      .get(`${ILLI_API_SERVER_URL}/residents?system=${this.system.id}`)
      .then((response) => {
        this.residents = response.data.residents
        this.loading = false;
        this.residents = response.data.residents
      })
      .catch((error) => {
        this.showServerErrorDialog = true;
        console.error(error)
        this.loading = false;
      });
    },
    showAddResidentDialogAndResetResidentName() {
      this.newResidentName = "";
      this.showAddResidentDialog = true;
    },
    addResidentFunction() {
      if (this.newResidentName === "") {
        this.showInsertNameError = true;
      } else {
        axios
          .post(`${ILLI_API_SERVER_URL}/residents`, {
            system: this.system.id,
            resident: this.newResidentName
          })
          .then((response) => {
            if (response.status === 201) {
              this.showResidentAdded = true;
            }
          })
          .catch(() => {
            this.showServerErrorDialog = true;
          });

        this.newResidentName = "";
        this.showAddResidentDialog = false;
      }
    },
  },
};
</script>                